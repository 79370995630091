/** @jsx jsx */
import React, { createContext, useContext } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { useToggleLayer, anchor } from "react-laag"
import { jsx, Button, Card, Box } from 'theme-ui'

const MenuContext = createContext()
MenuContext.displayName = 'PopupMenuContext'

export const MenuItem = ({ onClick, children, destructive }) => {
  let context = useContext(MenuContext)
  return (
    <Button variant={destructive ? 'menuItemDestructive' : 'menuItem'} onClick={() => {
      if (onClick) onClick()
      context.closeMenu()
    }}>
      {children}
    </Button>
  )
}

export const PopUpMenu = ({ label, children, pos }) => {

  const [element, toggleLayerProps] = useToggleLayer(
    ({ close, layerProps, isOpen }) => {
      if (isOpen) {
        return (
          <div
            ref={layerProps.ref}
            style={{
              ...layerProps.style
            }}
          >
            <MenuContext.Provider value={{ closeMenu: close }}>
              <Card variant="menu" mb={4}>
                {children}
              </Card>
            </MenuContext.Provider>
          </div>
        )
      }
    },
    // provide some options, like placement
    {
      placement: {
        anchor: pos || anchor.BOTTOM_CENTER,
        triggerOffset: 5,
        autoAdjust: true,
      },
      ResizeObserver: ResizeObserver,
      fixed: true,
      closeOnOutsideClick: true,
      closeOnDisappear: 'full'
    }
  )

  return (
    <>
      {element}
      <Box variant="menuWrapper" onClick={toggleLayerProps.openFromMouseEvent}>
        {label}
      </Box>
    </>
  )
}