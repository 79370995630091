/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'
import { Header } from '@/components/Header'

export default ({ children, header }) => {
  return (
    <>
    {/* <Box sx={{ fontSize: 1, position: 'fixed', width: '100%', bg:'rgb(235, 235, 235)'}}>
      <Flex>
        <span sx={{ mr: 2, py: 2, px: 4, bg: 'background', borderTopRightRadius: 10 }}>Marks</span>
        <span sx={{ mx: 3, padding: 2 }}>Issues</span>
        <span sx={{ mx: 3, padding: 2 }}>Topics</span>
      </Flex>
    </Box> */}
    <Box variant='layout.wrapper'>
      <Header />
      <Box variant='layout.content'>
        {children}
      </Box>
    </Box>
    </>
  )
}