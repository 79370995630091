import React from 'react'
import Router from 'next/router'
import { Button } from 'theme-ui'
import { logEvent, EVENT } from '@/helpers/Events'
import firebase from '@/firebase/clientApp'
import { useFeatureGroup } from '@/context/featureGroupContext'

export const EditMark = (username, id) => {
  const { loading, isBeta } = useFeatureGroup()

  if (loading || !isBeta) {
    return null
  }

  function editMark() {
    Router.push('/[username]/[id]/edit', `/${username}/${id}/edit`)
  }

  return <Button onClick={editMark}>Edit mark</Button>
}

export const EditProfile = () => {
  function editProfile() {
    Router.push('/settings')
  }

  return <Button onClick={editProfile}>Edit</Button>
}

export const ReserveUsername = () => {
  function handleLogin() {
    logEvent(EVENT.signUp)
    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
  }

  return <Button onClick={handleLogin}>Reserve username</Button>
}

export const CreateNewsletter = () => {
  return <Button onClick={() => Router.push('/newsletters/new')}>Create newsletter</Button>
}