/** @jsx jsx */
import Link from 'next/link'
import Router from 'next/router'
import { jsx, Avatar, Text, Flex, Box, Link as ThemeUILink } from 'theme-ui'
import { useUserProfile } from '@/context/userProfileContext'
import { useAuthenticatedUser, logout } from '@/context/authContext'
import { useFeatureGroup } from '@/context/featureGroupContext'
import { ReserveUsername } from '@/components/Actions'
import { PopUpMenu, MenuItem } from '@/components/Menu'

export const HeaderScafolding = ({ left, center, right }) => {
  return (
    <Flex variant="layout.header">
      { left }
      { right }
    </Flex>
  )
}

export const Header = ({ right }) => {
  const { authenticatedUser, authIsLoading } = useAuthenticatedUser()

  // We're loading, just show the logo
  if (authIsLoading) {
    <HeaderScafolding
      left={<HeaderLogo />}
    />
  }

  // We have no authenticated user
  if (!authIsLoading && !authenticatedUser) {
    return <LoggedOutHeader />
  }

  return (
    <LoggedInHeader
      left={<HeaderLogo href="/" />}
      right={right}
    />
  )
}

const AvatarMenu = () => {
  const { profile } = useUserProfile()
  const { isBeta } = useFeatureGroup()

  return (
    <PopUpMenu label={<Avatar variant="avatarMenu" src={profile.avatarURL} />}>
      {isBeta &&
        <>
          <MenuItem onClick={() => Router.push('/home')}>
            <Text>Marks</Text>
          </MenuItem>
          <MenuItem onClick={() => Router.push('/newsletters')}>
            <Text>Newsletters</Text>
          </MenuItem>
        </>
      }
      <MenuItem onClick={() => Router.push('/[username]', `/${profile.username}`)}>
        <Text>Profile</Text>
      </MenuItem>
      <MenuItem onClick={logout}>
        <Text>Log out</Text>
      </MenuItem>
    </PopUpMenu>
  )
}

const LoggedInHeader = ({center, right}) => {
  const { profile, loading } = useUserProfile()

  if (loading || !profile) {
    return <HeaderScafolding
      left={<HeaderLogo />}
    />
  }

  return (
    <HeaderScafolding
      left={
        <HeaderLogo href='/home' />
      }
      right={
        <AvatarMenu />
      }
    />
  )
}

const LoggedOutHeader = () => {
  return (
    <HeaderScafolding
      left={<HeaderLogo href='/' />}
      right={<ReserveUsername />}
    />
  )
}

const HeaderLogo = ({ href }) => {
  if (!href) {
    return <ThemeUILink variant="logo">Sharemark</ThemeUILink>
  }
  return (
    <Link href={href} passHref>
      <ThemeUILink variant="logo">Sharemark</ThemeUILink>
    </Link>
  )
}